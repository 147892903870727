import { Exchange } from "./pages/exchange";
import React from "react";

export const App = () => {
    return (
        <div className="App">
            <Exchange />
        </div>
    );
};
